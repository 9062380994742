<template>
  <div class="jsgl-page">
    <!-- 角色管理 -->
    <el-form
      class="params-wrap"
      size="small"
      :inline="true"
      :model="queryParams"
    >
      <el-form-item>
        <el-input
          v-model="queryParams.name"
          clearable
          placeholder="请输入角色名称"
          @change="resetPageAndRefresh"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="info"
          icon="el-icon-search"
          @click="resetPageAndRefresh"
          >搜索</el-button
        >
      </el-form-item>
      <el-form-item class="fr">
        <el-button
          type="success"
          size="small"
          icon="el-icon-plus"
          @click="handleForm(false)"
          v-if="OA.includes('systems:role:add')"
          >添加</el-button
        >
      </el-form-item>
    </el-form>

    <div class="content-wrap">
      <!--列表-->
      <paged-table
        :data="roleList"
        :total="roleTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      >
        <el-table-column
          sortable="custom"
          prop="name"
          show-overflow-tooltip
          label="角色名称"
          width="250"
        ></el-table-column>
        <el-table-column
          sortable="custom"
          prop="code"
          show-overflow-tooltip
          label="角色编码"
        ></el-table-column>
        <el-table-column
          sortable="custom"
          show-overflow-tooltip
          label="角色类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.type == 1 ? "系统角色" : "自定义角色" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          show-overflow-tooltip
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{
              scope.row.status == 1 ? "启用" : "停用"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="mini" title="分配菜单权限" @click="handleRole(scope.row)" v-if="OA.includes('systems:role:assign')" ><img class="loginHead" src="@/assets/images/powers.svg" alt=""></el-button>
            <el-button
              size="mini"
              @click="handleDetails(scope.row)"
              icon="el-icon-search"
              title="详情"
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleForm(true, scope.row)"
              icon="el-icon-edit"
              title="编辑"
              v-if="OA.includes('systems:role:editor')"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete({ id: scope.row.id })"
              icon="el-icon-delete"
              v-if="OA.includes('systems:role:del')"
              title="删除"
            ></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog
      :title="isEditor ? '编辑角色' : '添加角色'"
      :visible.sync="aVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        ref="formFileds"
        :model="form"
        :rules="formRules"
        size="small"
      >
        <el-form-item label="角色名称：" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色编码：" prop="code">
          <el-input v-model="form.code" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色类型：" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">系统角色</el-radio>
            <el-radio :label="2">自定义角色</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="bLoading"
          @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog
      title="角色详情"
      :visible.sync="bVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        ref="detailsFileds"
        :model="details"
        size="small"
      >
        <el-form-item label="角色名称：" prop="name">
          <span v-text="details.name"></span>
        </el-form-item>
        <el-form-item label="角色编码：" prop="code">
          <span v-text="details.code"></span>
        </el-form-item>
        <el-form-item label="角色类型：" prop="code">
          <span v-text="details.type == 1 ? '系统角色' : '自定义角色'"></span>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="角色分配菜单"
      width="80%"
      :visible.sync="cVisible"
      :modal="true"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
    >
      <el-tree
        v-if="cVisible"
        :data="resources"
        @check="clickTree"
        @check-change="checkChange"
        default-expand-all
        :expand-on-click-node="false"
        check-strictly
        show-checkbox
        node-key="id"
        ref="tree"
        :props="defaultProps"
      ></el-tree>

      <div class="right">
        <el-button @click.native="cVisible = false">取消</el-button>
        <el-button type="primary" @click="saveTreeData" :loading="bLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "roleManagement",
  components: {
    PagedTable,
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        name: null,
      },

      form: {
        id: "",
        name: "",
        code: "",
        type: "",
        status: "",
        saas: ''
      },
      details: {
        name: "",
        code: "",
        type: "",
        extend: {},
      },
      formRules: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入角色编码", trigger: "blur" }],
      },

      roleId:'',
      resources: [],
      defaultProps: {
        label: "title",
      },
      curCheckId: "",
      curCheckResult: false,

      pageApi: "commonRoleList",
      insertApi: "commonRoleAdd",
      editorApi: "commonRoleEdit",
      deleteApi: "commonRoleDelete",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemRole", ["roleList", "roleTotal"]),
  },
  methods: {
    ...mapActions("systemMenu", ["commonMenuGetAllMenuTree"]),
    ...mapActions("systemRole", [
      "commonRoleList",
      "commonRoleAdd",
      "commonRoleEdit",
      "commonRoleDelete",
      "commonRoleMenuSelectAll"
    ]),

    async handleRole(row) {
      this.cVisible = true;
      this.roleId = row.id;
      try{
        this.resources = await this.commonMenuGetAllMenuTree({})
        let res = await this.commonRoleMenuSelectAll({roleId:row.id})
        if(res.length){
          this.$refs.tree.setCheckedKeys(res.map(item=>item.menuId))
        }
      }catch(e){
        log(e)
      }
    },
    checkChange(data, checked) {
      this.curCheckResult = checked;
      this.curCheckId = data.id;
      if (checked) {
        this.$refs.tree.setChecked(data.parentId, true);
      } else {
        this.setListChecked(data);
      }
    },
    setListChecked(list) {
      if (list.children && list.children.length) {
        list.children.forEach((k, index) => {
          this.$refs.tree.setChecked(k.id, true);
          this.setListChecked(k);
        });
      }
    },
    clickTree(data, checked) {
      setTimeout(() => {
        if (this.curCheckResult) {
          this.setListChecked(data);
        }
      });
    },
    async saveTreeData() {
      this.bLoading = true
      try{
        await this.$confirm('确认提交菜单数据吗？');
        let menuIds = this.$refs.tree.getCheckedKeys().toString();
        await this.commonRoleEdit({ id:this.roleId, roleMenuIds:menuIds })
        this.$message.success('保存成功')
      }catch(e){
        log(e)
      }finally{
        this.bLoading = false
        this.cVisible = false
      }
    },
  },
  async mounted() {
    if (this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name));
    this.refresh();
    this.form.saas = sessionStorage.getItem('shopId')
    this.queryParams.sass = sessionStorage.getItem('shopId')
  },
};
</script>

<style lang="scss">
.jsgl-page {
    .el-tree-node__content:hover {
        background-color: #fff;
    }
    .el-tree-node__children {
        display: flex !important;
        flex-wrap: wrap;
        .el-tree-node {
            display: inline-block;
            .el-tree-node__children {
                display: block !important;
                flex-wrap: normal;
                .el-tree-node {
                    display: block;
                }
            }
        }
    }
    .right { text-align:right; margin-top:30px; }
}
</style>